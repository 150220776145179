'use client';

import React, { useEffect, useRef, useCallback } from 'react';

const videoUrls = [
  'https://media.ariaflow.ai/ariaflow/video/04266c58-e5a1-4d03-a802-c4a88876811d.mp4',
  'https://media.ariaflow.ai/ariaflow/video/245b38ec-d7fa-4ed9-9f7e-b4b95f0bb5f8.mp4',
  'https://video-cdn.autoshorts.ai/demos/n7pk1hz19z.mp4',
  'https://video-cdn.autoshorts.ai/demos/4l8g299olsk.mp4',
  'https://video-cdn.autoshorts.ai/demos/lqljgm9xljl.mp4',
  'https://video-cdn.autoshorts.ai/demos/lqkzompfhq8.mp4',
  'https://video-cdn.autoshorts.ai/demos/y6ccmo66yp.mp4',
  'https://video-cdn.autoshorts.ai/demos/scary.mp4',
  'https://video-cdn.autoshorts.ai/demos/motivational.mp4',
];

const VideoCarousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const videoRefs = useRef<HTMLVideoElement[]>([]);

  const muteAllVideos = useCallback(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.muted = true;
        video.volume = 0;
        video.play().catch(error => console.error('自动播放失败:', error));
      }
    });
  }, []);

  useEffect(() => {
    muteAllVideos();
    const handlers = {
      pageshow: () => muteAllVideos(),
      focus: () => muteAllVideos(),
      visibilitychange: () => {
        if (document.visibilityState === 'visible') muteAllVideos();
      }
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      if (event === 'visibilitychange') {
        document.addEventListener(event, handler);
      } else {
        window.addEventListener(event, handler);
      }
    });

    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        if (event === 'visibilitychange') {
          document.removeEventListener(event, handler);
        } else {
          window.removeEventListener(event, handler);
        }
      });
    };
  }, [muteAllVideos]);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (carouselRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
        const maxScroll = scrollWidth - clientWidth;
        if (scrollLeft >= maxScroll) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft += 1;
        }
      }
    };

    const animationInterval = setInterval(updateScrollPosition, 20);
    return () => clearInterval(animationInterval);
  }, []);

  return (
    <section className="relative py-16 md:py-24 bg-gradient-to-b from-base-200/50 to-base-100">
      {/* Decorative elements */}
      <div className="absolute inset-0 bg-grid-primary/5 bg-[length:20px_20px] [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-primary/5 to-transparent" />
      <div className="absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-primary/5 to-transparent" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 md:mb-16 space-y-4">
          <div className="inline-flex items-center justify-center space-x-2 bg-primary/10 rounded-full px-4 py-1 text-sm font-medium text-primary-content mb-4">
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-primary"></span>
            </span>
            <span>AI-Generated Content</span>
          </div>
          
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary via-secondary to-accent">
              Viral Faceless Videos
            </span>
            <span className="block text-base-content">Powered by AI</span>
          </h2>
          <p className="text-lg md:text-xl text-base-content/80 max-w-2xl mx-auto">
            Create captivating content in minutes, choose your theme, and go viral with our AI-powered video generation
          </p>
        </div>
        
        <div className="relative mt-8">
          {/* Gradient overlays */}
          <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-base-100 to-transparent z-10" />
          <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-base-100 to-transparent z-10" />
          
          <div 
            ref={carouselRef}
            className="flex overflow-x-hidden gap-4"
            style={{ scrollBehavior: 'smooth' }}
          >
            {videoUrls.concat(videoUrls).map((url, index) => (
              <div key={index} className="flex-none w-44 sm:w-52 md:w-60 transform transition-all duration-300 hover:scale-105">
                <div className="relative group">
                  {/* Glow effect */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-primary to-secondary rounded-2xl blur opacity-30 group-hover:opacity-60 transition duration-500" />
                  
                  <div className="relative bg-base-100 rounded-2xl shadow-xl overflow-hidden">
                    <div className="aspect-[9/16]">
                      <video
                        ref={el => {
                          if (el) {
                            videoRefs.current[index] = el;
                            el.muted = true;
                            el.volume = 0;
                          }
                        }}
                        src={url}
                        className="w-full h-full object-cover"
                        playsInline
                        autoPlay
                        loop
                        muted
                        onLoadedMetadata={muteAllVideos}
                        onCanPlay={muteAllVideos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoCarousel;
